<template>
  <div class="page-table mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">Günlük Faaliyet Tanımla</div>
    <el-row :gutter="10" v-loading="loading"
            element-loading-text='Müşteri Listesi Getiriliyor'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="faaliyetForm" :rules="rulesForm" ref="faaliyetForm"
               class="demo-ruleForm faaliyet-form">
        <el-col :lg="18" :md="24" :sm="24" :xs="24">
          <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-20 pt-10 mt-10">
            <el-row>
              <el-col :lg="4" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Firma" prop="firma">:
                  <el-select style="width: 100%" size="small" auto-complete="off" v-model="faaliyetForm.musteriID"
                             placeholder="Firma Seçiniz" filterable :filter-methos="filterMethod"
                             @change="getProjeListesi">
                    <el-option v-for="item in musteriler" aria-autocomplete="none" :key="item.musteriID"
                               :label="item.musteriAdi"
                               :value="item.musteriID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="4" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Proje" prop="proje">:
                  <el-select @change="projeChange($event)" style="width: 97%; text-align: center;" size="small"
                             v-model="faaliyetForm.projeID"
                             placeholder="Proje Seçiniz" filterable
                             :filter-methos="filterMethod"
                             :no-data-text="faaliyetForm.musteriID ? 'Firmaya Ait Proje Bulunamadı':'Önce Firma Seçiniz'">
                    <el-option
                        v-for="item in projeler"
                        :key="item.projeID"
                        :label="item.projeBaslik"
                        :value="item.projeID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Faaliyet Başlığı" prop="faaliyetBaslik">:
                  <el-input size="small"
                            v-model="faaliyetForm.faaliyetBaslik"
                            :placeholder="'Faaliyet Başlığı Giriniz'">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="4" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Faaliyet Tarihi" prop="tarih">:
                  <el-date-picker
                      v-model="faaliyetForm.tarih"
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      size="small"
                      :picker-options="pickerOptions"
                      placeholder="Tarihi Seçiniz">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :lg="4" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Gün">:
                  <el-input size="small" v-model="faaliyetForm.gun">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="8" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Hazırlayan" prop="hazirlayan">:
                  <el-input size="small" v-model="faaliyetForm.hazirlayan">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="12" :sm="12" :xs="12" class="col-p">
                <el-form-item label="Şantiye Şefi" prop="santiyeSefi">:
                  <el-input size="small" v-model="faaliyetForm.santiyeSefi">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item label="İşin Durumu" prop="isDurumu">:
                  <el-radio-group v-model="faaliyetForm.isDurumu" size="medium" style="display: flex">
                    <el-radio-button label="1">
                      <i class="mdi mdi-check mr-5"></i> Tamamlandı
                    </el-radio-button>
                    <el-radio-button label="2">
                      <i class="mdi mdi-chevron-right mr-5"></i> Devam Ediyor
                    </el-radio-button>
                    <el-radio-button label="0">
                      <i class="mdi mdi-stop mr-5"></i> İptal
                    </el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-divider class="faaliyet-first-divider"/>
              <el-col :lg="10" :md="24" :sm="24" :xs="24">
                <el-card class="m-10 personel-data-card">
                  <p class="m-0 mb-10 faaliyet-card-title">Teknik Personel Bilgileri</p>
                  <el-divider/>
                  <el-row v-if="personelData.length > 0">
                    <el-col :span="2"><p></p></el-col>
                    <el-col :span="15"><p class="mt-0">Teknik Personel</p></el-col>
                    <el-col :span="6"><p class="mt-0">Sayısı</p></el-col>
                  </el-row>
                  <el-row v-for="item in personelData" :key="item.id" class="mt-2 input-row">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3">
                      <el-button size="mini"
                                 @click="tableRemoveItem('personelData',item.id)"
                                 type="danger"
                                 icon="el-icon-minus"
                                 circle plain></el-button>
                    </el-col>
                    <el-col :lg="15" :md="15" :sm="14" :xs="14">
                      <el-input size="mini" v-model="item.teknikPersonel"/>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number :min="0" size="mini" v-model="item.sayi"/>
                    </el-col>
                  </el-row>
                  <el-button
                      size="mini"
                      @click="tableAddItem('personelData')"
                      type="primary"
                      class="mt-5"
                      icon="el-icon-plus"
                      circle plain></el-button>
                </el-card>
                <el-card class="m-10 diger-personel-data-card">
                  <p class="m-0 mb-10 faaliyet-card-title">Diğer Personel Bilgileri</p>
                  <el-divider/>
                  <el-row v-if="digerPersonelData.length > 0">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3"><p></p></el-col>
                    <el-col :lg="15" :md="15" :sm="14" :xs="14"><p class="mt-0">Diğer Personel</p></el-col>
                    <el-col :span="6"><p class="mt-0">İşçi Sayısı</p></el-col>
                  </el-row>
                  <el-row v-for="item in digerPersonelData" :key="item.id" class="mt-2 input-row">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3">
                      <el-button size="mini" @click="tableRemoveItem('digerPersonelData',item.id)"
                                 type="danger"
                                 icon="el-icon-minus"
                                 circle plain/>
                    </el-col>
                    <el-col :lg="15" :md="15" :sm="14" :xs="14">
                      <el-input size="mini" v-model="item.personel"/>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number :min="0" size="mini" v-model="item.sayi"/>
                    </el-col>
                  </el-row>
                  <el-button
                      size="mini"
                      @click="tableAddItem('digerPersonelData')"
                      type="primary"
                      class="mt-5"
                      icon="el-icon-plus"
                      circle plain></el-button>
                </el-card>
              </el-col>
              <el-col :lg="14" :md="24" :sm="24" :xs="24">
                <el-card class="m-10 makine-data-card">
                  <p class="m-0 mb-10 faaliyet-card-title">Makine Ekipman Bilgileri</p>
                  <el-divider/>
                  <el-row v-if="makineData.length > 0">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3"><p></p></el-col>
                    <el-col :lg="11" :md="11" :sm="8" :xs="8"><p class="mt-0">Makine Ekipman</p></el-col>
                    <el-col :span="6"><p class="mt-0">Sayı / Bölüm</p></el-col>
                    <el-col :lg="5" :md="5" :sm="7" :xs="7"><p class="mt-0 makine-saat-text">Saat</p></el-col>
                  </el-row>
                  <el-row v-for="item in makineData" :key="item.id" class="mt-2 input-row">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3">
                      <el-button size="mini" @click="tableRemoveItem('makineData',item.id)"
                                 type="danger"
                                 icon="el-icon-minus"
                                 circle plain/>
                    </el-col>
                    <el-col :lg="11" :md="11" :sm="8" :xs="8">
                      <el-input size="mini" v-model="item.makine"/>
                    </el-col>
                    <el-col :span="6">
                      <el-input-number :min="0" size="mini" v-model="item.sayi"/>
                    </el-col>
                    <el-col :lg="5" :md="5" :sm="7" :xs="7">
                      <el-input class="makine-saat-input" size="mini" v-model="item.saat"/>
                    </el-col>
                  </el-row>
                  <el-button
                      size="mini"
                      @click="tableAddItem('makineData')"
                      type="primary"
                      class="mt-5"
                      icon="el-icon-plus"
                      circle plain></el-button>
                </el-card>
                <el-card class="m-10 malzeme-data-card">
                  <p class="m-0 mb-10 faaliyet-card-title">Malzeme Bilgileri</p>
                  <el-divider/>
                  <el-row v-if="malzemeData.length > 0">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3"><p></p></el-col>
                    <el-col :lg="11" :md="11" :sm="10" :xs="10"><p class="mt-0">Gelen Malzeme</p></el-col>
                    <el-col :span="6"><p class="mt-0">Bölüm</p></el-col>
                    <el-col :span="4"><p class="mt-0">Miktar</p></el-col>
                  </el-row>
                  <el-row v-for="item in malzemeData" :key="item.id" class="mt-2 input-row">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3">
                      <el-button size="mini" @click="tableRemoveItem('malzemeData',item.id)" type="danger"
                                 icon="el-icon-minus"
                                 circle plain/>
                    </el-col>
                    <el-col :lg="11" :md="11" :sm="10" :xs="10">
                      <el-input size="mini" v-model="item.malzeme"/>
                    </el-col>
                    <el-col :span="6">
                      <el-input size="mini" v-model="item.bolum"/>
                    </el-col>
                    <el-col :span="5">
                      <el-input size="mini" v-model="item.miktar"/>
                    </el-col>
                  </el-row>
                  <el-button
                      size="mini"
                      @click="tableAddItem('malzemeData')"
                      type="primary"
                      class="mt-5"
                      icon="el-icon-plus"
                      circle plain></el-button>
                </el-card>
                <el-card class="m-10 imalatlar-data-card">
                  <p class="m-0 mb-10 faaliyet-card-title">İmalat Bilgileri</p>
                  <el-divider/>
                  <el-row v-if="imalatData.length > 0">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3"><p></p></el-col>
                    <el-col :lg="22" :md="22" :sm="21" :xs="21"><p class="mt-0">GÜNLÜK İMALATLAR</p></el-col>
                  </el-row>
                  <el-row v-for="item in imalatData" :key="item.id" class="mt-2 input-row">
                    <el-col :lg="2" :md="2" :sm="3" :xs="3">
                      <el-button size="mini" @click="tableRemoveItem('imalatData',item.id)" type="danger"
                                 icon="el-icon-minus"
                                 circle plain/>
                    </el-col>
                    <el-col :lg="22" :md="22" :sm="21" :xs="21">
                      <el-input size="mini" v-model="item.imalat"/>
                    </el-col>
                  </el-row>
                  <el-button
                      size="mini"
                      @click="tableAddItem('imalatData')"
                      type="primary"
                      class="mt-5"
                      icon="el-icon-plus"
                      circle plain></el-button>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :lg="6" :md="24" :sm="24" :xs="24">
          <el-collapse class="mt-10 yanForm animated fadeInUp" v-model="activeCollapse">
            <el-collapse-item class="card-shadow--medium" name="2"
                              title='İşlem'>
              <el-row>
                <el-button @click="faaliyetEkle('faaliyetForm')" size="mini" class="onayBtn mt-10" type="primary">
                  <label>Kaydet</label>
                </el-button>
                <el-popover placement="bottom" width="310" v-model="visibleSil">
                  <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button size="mini" type="primary" @click="resetForm('faaliyetForm')">
                      {{ $t('src.views.apps.genel.yes') }}
                    </el-button>
                    <el-button size="mini" @click="visibleSil = false;">
                      {{ $t('src.views.apps.genel.no') }}
                    </el-button>
                  </div>
                  <el-button size="mini" slot="reference">
                    {{ $t('src.views.apps.genel.temizle') }}
                  </el-button>
                </el-popover>
              </el-row>
              <el-row class="m-15">
                <el-divider/>
                <el-col :lg="24" :md="24" :sm="24" :xs="24">
                  <el-form-item label="Hava Durumu">:
                    <el-radio-group v-model="faaliyetForm.havaDurumu" size="medium">
                      <el-radio-button label="1">
                        <i class="mdi mdi-weather-sunny mr-5"></i> Açık
                      </el-radio-button>
                      <el-radio-button label="2">
                        <i class="mdi mdi-weather-cloudy mr-5"></i> Kapalı
                      </el-radio-button>
                      <el-radio-button label="3">
                        <i class="mdi mdi-weather-fog mr-5"></i> Sisli
                      </el-radio-button>
                      <el-radio-button label="4">
                        <i class="mdi mdi-weather-pouring mr-5"></i> Yağışlı
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <p class="font-weight-600">Dosya Ekle</p>
                <el-upload :file-list="faaliyetForm.dosyalar" :on-change="fileUpload" multiple action=""
                           list-type="picture-card" :auto-upload="false">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="fileRemove(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import notification from '../../../notification'
import functions from '../../../functions'
import EventBus from '@/components/event-bus'
import Resim from '@/components/Resim.vue'
import Seo from '@/components/Seo.vue'
import Konumlar from '@/components/Konumlar.vue'
import JQuery from 'jquery';
import faaliyetService from "@/WSProvider/FaaliyetService";
import musteriService from "@/WSProvider/MusteriService";
import jwt_decode from "jwt-decode";

let $ = JQuery;
var moment = require('moment');
let time = moment().format('YYYY-MM-DD');

export default {
  name: "FaaliyetTanimla",
  components: {
    draggable,
    Resim,
    Seo,
    Konumlar
  },
  mounted() {
    this.getMusteriListesi();
  },
  computed: {
    faaliyetStore() {
      return this.$store.getters.getFaaliyetData
    },
    tatilList() {
      return this.$store.getters.getTatilList
    },
    pickerOptions() {
      const self = this
      return {
        disabledDate(time) {
          const dateList = []
          self.tatilList.forEach(item => dateList.push(new Date(item.baslamaTarihi).toString()))
          const veri = dateList.find(item => item == time)
          return veri
        }
      }
    },
  },
  created() {
    if (this.faaliyetStore.musteriID) {
      this.getProjeListesi(this.faaliyetStore.musteriID)
      this.faaliyetForm.musteriID = this.faaliyetStore.musteriID
    }
    if (this.faaliyetStore.projeID) {
      this.faaliyetForm.projeID = this.faaliyetStore.projeID
    }
  },
  data() {
    var validateFirma = (rule, value, callback) => {
      if (this.faaliyetForm.musteriID === '') {
        callback(new Error('Lütfen Firma Seçiniz'));
      } else {
        callback();
      }
    };
    var validateProje = (rule, value, callback) => {
      if (this.faaliyetForm.projeID === '') {
        callback(new Error('Lütfen Proje Seçiniz'));
      } else {
        callback();
      }
    };
    return {
      projeler: [],
      musteriler: [],
      personelData: [
        {id: 0, teknikPersonel: 'ŞANTİYE ŞEFİ', sayi: 1},
        {id: 1, teknikPersonel: 'İNŞAAT MÜHENDİSİ', sayi: ''},
        {id: 2, teknikPersonel: 'MİMAR', sayi: ''},
        {id: 3, teknikPersonel: 'MAKİNE MÜHENDİSİ', sayi: ''},
        {id: 4, teknikPersonel: 'ELEKTRİK MÜHENDİSİ', sayi: ''},
        {id: 5, teknikPersonel: 'HARİTA MÜHENDİSİ', sayi: ''},
      ],
      digerPersonelData: [
        {id: 0, personel: 'KALIPÇI', sayi: ''},
        {id: 1, personel: 'DEMİRCİ', sayi: ''},
        {id: 2, personel: 'PREFABRİK MONTAJI', sayi: ''},
        {id: 3, personel: 'PANEL MONTAJI', sayi: ''},
        {id: 4, personel: 'KAYNAK EKİBİ', sayi: ''},
        {id: 5, personel: 'DUVAR', sayi: ''},
        {id: 6, personel: 'SIVA', sayi: ''},
        {id: 7, personel: 'ALÇI-BOYA', sayi: ''},
        {id: 8, personel: 'YALITIM-MOZAİK', sayi: ''},
        {id: 9, personel: 'SIHHİ Tesisat', sayi: ''},
        {id: 10, personel: 'SAHA BETONU EKİBİ', sayi: ''},
        {id: 11, personel: 'DÜZ İŞÇİ', sayi: ''},
        {id: 12, personel: 'ELEKTRİK', sayi: ''},
      ],
      makineData: [
        {id: 0, makine: 'MOBİL VİNÇ', sayi: '', saat: ''},
        {id: 1, makine: 'MANITOU', sayi: '', saat: ''},
        {id: 2, makine: 'EKSKAVATÖR', sayi: '', saat: ''},
        {id: 3, makine: 'SİLİNDİR', sayi: '', saat: ''},
        {id: 4, makine: 'MANLİFT', sayi: '', saat: ''},
        {id: 5, makine: 'GREYDER', sayi: '', saat: ''},
        {id: 6, makine: 'LODER', sayi: '', saat: ''},
        {id: 7, makine: 'BEKO LODER', sayi: '', saat: ''},
        {id: 8, makine: 'JCB', sayi: '', saat: ''},
        {id: 9, makine: 'SONDAJ DELGİ', sayi: '', saat: ''},
      ],
      malzemeData: [],
      imalatData: [],
      loading: false,
      activeCollapse: ['1', '2'],
      visibleSil: false,
      disabled: false,
      faaliyetForm: {
        musteriID: '',
        projeID: '',
        faaliyetBaslik: '',
        havaDurumu: '',
        isDurumu: '',
        tarih: time,
        gun: '',
        hazirlayan: jwt_decode(localStorage.getItem("userDataBGSurec")).yoneticiAdSoyad || '',
        santiyeSefi: jwt_decode(localStorage.getItem("userDataBGSurec")).yoneticiAdSoyad || '',
        dosyalar: [],
      },
      rulesForm: {
        firma: [{required: true, validator: validateFirma, message: "Lütfen Firma Seçiniz", trigger: 'blur'}],
        proje: [{required: true, validator: validateProje, message: "Lütfen Proje Seçiniz", trigger: 'blur'}],
        faaliyetBaslik: [{
          required: true,
          message: "Lütfen Faaliyet Başlığını Giriniz",
          trigger: 'blur'
        }],
        tarih: [{
          required: true,
          message: "Lütfen Tarihi Giriniz",
          trigger: 'blur'
        }],
        hazirlayan: [{
          required: true,
          message: "Lütfen Hazırlayanı Giriniz",
          trigger: 'blur'
        }],
        santiyeSefi: [{
          required: true,
          message: "Lütfen Şantiye Şefini Giriniz",
          trigger: 'blur'
        }],
        isDurumu: [{
          required: true,
          message: "Lütfen İş Durumunu Seçiniz.",
          trigger: 'blur'
        }],
      },
    }
  },
  methods: {
    projeChange(event) {
      const data = this.projeler.find(item => item.projeID == event)
      var duration = moment.duration(moment().diff(moment(data.baslangicTarihi)));
      var days = duration.asDays();
      this.faaliyetForm.gun = Math.ceil(days)
    },
    fileRemove(file) {
      this.faaliyetForm.dosyalar.splice(this.faaliyetForm.dosyalar.indexOf(file), 1);
    },
    fileUpload(file) {
      // this.faaliyetForm.dosyalar.push(file.raw);
      this.faaliyetForm.dosyalar.push(file);
    },
    getProjeListesi(val) {
      try {
        this.faaliyetForm.projeID = ''
        musteriService.musteriProjeleri("", "", val || this.faaliyetForm.musteriID, 1).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.count && response.count != 0) {
            this.projeler = response.data;
          }
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.projeler = [];
            } else if (error.status == 503) {
              this.projeler = []
              notification.Status("warning", this, "Bu müşterinin projesi bulunmamaktadır.")
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.projeLoading = false
      }
    },
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    getMusteriListesi() {
      try {
        this.loading = true;
        musteriService.musteriListesi(1, 0, 10000).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.musteriler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.musteriler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    tableRemoveItem(tur, id) {
      this[tur].splice(this[tur].findIndex(item => item.id === id), 1)
    },
    tableAddItem(tur) {
      if (tur === 'personelData') {
        this.personelData.push({
          id: this[tur].length > 1 ? this[tur][[this[tur].length - 1]].id + 1 : Math.floor((Math.random() * 10000) + 1),
          teknikPersonel: '',
          sayi: '',
        })
      }
      if (tur === 'digerPersonelData') {
        this.digerPersonelData.push({
          id: this[tur].length > 1 ? this[tur][[this[tur].length - 1]].id + 1 : Math.floor((Math.random() * 10000) + 1),
          personel: '',
          sayi: '',
        })
      }
      if (tur === 'makineData') {
        this.makineData.push({
          id: this[tur].length > 1 ? this[tur][[this[tur].length - 1]].id + 1 : Math.floor((Math.random() * 10000) + 1),
          makine: '',
          sayi: '',
          saat: '',
        })
      }
      if (tur === 'malzemeData') {
        this.malzemeData.push({
          id: this[tur].length > 1 ? this[tur][[this[tur].length - 1]].id + 1 : Math.floor((Math.random() * 10000) + 1),
          malzeme: '',
          bolum: '',
          miktar: '',
        })
      }
      if (tur === 'imalatData') {
        this.imalatData.push({
          id: this[tur].length > 1 ? this[tur][[this[tur].length - 1]].id + 1 : Math.floor((Math.random() * 10000) + 1),
          imalat: '',
        })
      }
    },
    faaliyetEkle(formName) {
      const personel = []
      const digerPersonel = []
      const makine = []
      const malzeme = []
      const imalat = []
      this.personelData.forEach(item => personel.push({teknikPersonel: item.teknikPersonel, sayi: item.sayi}))
      this.digerPersonelData.forEach(item => digerPersonel.push({personel: item.personel, sayi: item.sayi}))
      this.makineData.forEach(item => makine.push({makine: item.makine, sayi: item.sayi, saat: item.saat}))
      this.malzemeData.forEach(item => malzeme.push({malzeme: item.malzeme, bolum: item.bolum, miktar: item.miktar}))
      this.imalatData.forEach(item => imalat.push({imalat: item.imalat}))
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Faaliyet tanımlamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            faaliyetService.faaliyetEkle(personel, digerPersonel, makine, malzeme, imalat, this.faaliyetForm).then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token)
                this.resetForm(formName)
                EventBus.$emit("faaliyetList")
                functions.sayfaKapat("faaliyettanimla", this);
              }
              this.loading = false;
              notification.Status("success", this, response.msg)
            }).catch(err => {
              if (err.responseJSON) {
                let error = err.responseJSON
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg)
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            })
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
      this.faaliyetForm.havaDurumu = ''
      this.faaliyetForm.dosyalar = []
      this.projeler = []
      this.musteriler = []
      this.personelData = []
      this.digerPersonelData = []
      this.makineData = []
      this.malzemeData = []
      this.imalatData = []
    },
  },
  beforeDestroy() {
    this.$store.commit("setFaaliyetData")
    window.removeEventListener('resize', this.checkDevice);
  },
}

</script>
<style scoped lang="scss">
.faaliyet-form {
  .faaliyet-first-divider {
    margin-top: 0 !important;
  }

  .faaliyet-dosya {
    float: right;
  }

  @media screen and (max-width: 500px) {
    .faaliyet-dosya {
      float: left;
    }
    .makine-saat-text {
      text-align: center;
    }
    .makine-saat-input {
      width: 80px;
      float: right;
    }
    .el-button--mini.is-circle {
      padding: 5px;
    }
    .el-input-number--mini {
      width: 110px !important;
    }
  }

  .el-divider--horizontal {
    margin: 12px 0;
  }


  .faaliyet-card-title {
    font-weight: 700;
    font-size: 15px;
  }

  .imalatlar-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .malzeme-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }

    .input-row .el-col:nth-child(3) .el-input {
      width: 90% !important;
    }
  }

  .makine-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .personel-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .diger-personel-data-card {
    .input-row .el-col:nth-child(2) .el-input {
      width: 90% !important;
    }
  }

  .table-first-column {
    display: contents;
  }

  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
<style lang="scss">
.faaliyet-form {
  .el-table_1_column_1 {
    text-align: center;
  }

  .el-table_2_column_3 {
    text-align: center;
  }
}

</style>